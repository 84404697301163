.App {
  font-family: sans-serif;
}

.sticky-wrapper2 {
  position: "-webkit-sticky";
}

.nonsticky {
  padding: "0px";
}

.nonsticky-middle {
  padding-top: "0px";
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.FullWidth {
  width: 100%;
}

.CWidth {
  width: 96%;
}

.AboutLink {
  color: grey
}

.FooterLink {
  font-size: 14px;
  color: black;
  padding-bottom: 12px;
  display: block;
}

.Contributors {
  font-size: 14px;
}

h2 {
  font-size: 48px;
}

span {
  font-size: 12px;
  color: gray;
}

p {
  font-size: 15px;
  line-height: 24px;
}

i {
   font-style: italic; 
}

b {
  font-style: bold; 
}

h4 {
  padding: 0px;
  margin-top: 20px;
}
